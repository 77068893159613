import axiosInstance from '../api/axios';
import authHeader from './auth-header';
import { setTokenCookie } from '../utils/cookies';

const API_URL = '/api/user/meetings';
const API_URL_FILTER_UPCOMING = `${API_URL}/all?filter=upcoming`;
const API_URL_UPCOMING_DESC = '&orderBy=desc';

const getAllMeetings = async () => {
  return axiosInstance
    .get(`${API_URL}/all?orderBy=desc`, { headers: authHeader() })
    .then((response) => {
      if (response.data.accessToken) {
        setTokenCookie(response.data.accessToken);
      }
      return response.data;
    })
    .catch((error) => {
      throw error.response
        ? error.response.data
        : new Error('Failed to get all meetings');
    });
};

const getMeeting = async (code) => {
  return axiosInstance
    .get(API_URL + '/' + code, { headers: authHeader() })
    .then((response) => {
      if (response.data.accessToken) {
        setTokenCookie(response.data.accessToken);
      }
      return response.data;
    })
    .catch((error) => {
      throw error.response
        ? error.response.data
        : new Error('Failed to get the meeting');
    });
};

const getAllUpcomingMeetings = () => {
  return axiosInstance.get(API_URL_FILTER_UPCOMING + API_URL_UPCOMING_DESC, {
    headers: authHeader(),
  });
};

const postMeetings = async (meetupData) => {
  return axiosInstance
    .post(API_URL, meetupData, { headers: authHeader() })
    .then((response) => {
      if (response.data.accessToken) {
        setTokenCookie(response.data.accessToken);
      }
      return response.data;
    })
    .catch((error) => {
      throw error.response
        ? error.response.data
        : new Error('Failed to schedule a meeting');
    });
};

const updateMeeting = async (id, data) => {
  return axiosInstance
    .patch(`${API_URL}/${id}`, data, { headers: authHeader() })
    .then((response) => {
      if (response.data.accessToken) {
        setTokenCookie(response.data.accessToken);
      }

      return response.data;
    })
    .catch((error) => {
      throw error.response
        ? error.response.data
        : new Error('Failed to update the meeting');
    });
};

const joinMeeting = (code) => {
  let JOIN_URL = API_URL + '/join/' + code;
  console.log(JOIN_URL);
  return axiosInstance.get(JOIN_URL, { headers: authHeader() });
};

const deleteMeeting = (code) => {
  return axiosInstance.delete(API_URL + '/' + code, { headers: authHeader() });
};

const getUserInfo = () => {
  return axiosInstance.get('/api/user/auth/me', { headers: authHeader() });
};

const meetingsService = {
  getAllMeetings,
  getAllUpcomingMeetings,
  joinMeeting,
  postMeetings,
  updateMeeting,
  deleteMeeting,
  getUserInfo,
  getMeeting,
};

export default meetingsService;
